import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Mgabryel from "../images/marcin-gabryel2.png"
import Mgabryel2 from "../images/marcin-gabryel.png"
import Envelope from "../images/envelope.svg"
import Mobile from "../images/mobile.svg"
import Seo from "../images/stats.svg"
import Home from "../images/home.jpg"
import Web from "../images/web-programming.svg"
import Reklama from "../images/video-advertising.svg"
import Kontakt from "../images/kontakt.jpg"

const Page = () => (
    <Layout>
      <SEO title="Poznaj mnie" />

      <div class="container pt-5">
          <h1 class="mb-2">O mnie</h1>
          <p>Sprawdź dlaczego warto nawiązać ze mną współpracę.</p>
          <hr></hr>
          <div class="row my-5">
            <div class="col-sm-12 col-md-8">
              <div class="text-center pt-4">
                <h2 class="mb-3">Marcin<span class="red-dot">_</span>Gabryel</h2>
                <p class="lead">Web Developer</p>
              </div>
              <div>
                <p class="text-justify">Cześć, nazywam się Marcin Gabryel i cieszę się, że mnie odwiedziłeś! 
                Zajmuje się tworzeniem nowoczesnych stron internetowych oraz platform e-commerce.
                Kieruje się przede wszystkim prostotą, dbałością o szczegóły oraz pełnym 
                zaangażowaniem. Nie brakuje mi również kreatywności.</p>
                <p class="text-justify">Od ponad 3 lat zawodowo świadczę usługi programistyczne oraz marketingowe. 
                Na zgromadzone przeze mnie doświadczenie składa się ponad kilkadziesiąt kampanii marketingowych (SEO), 
                a także kilkanaście stron internetowych oraz platform e-commerce.</p>
                <p>Moje ostatnie realizacje możesz podejrzeć w zakładce <Link to="/realizacje"> #Portfolio</Link>.</p>
              </div>
            </div>
            <div class="col-sm-12 col-md-4 mt-5">
              <div class="w-75 mx-auto">
                <img src={Mgabryel} class="o-mnie" alt="Marcin Gabryel" />
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid pt-4 pb-3 bg-light">
          <div class="row mx-auto">
            <div class="col-sm-12 col-md-4 text-center">
              <p class="display-3">100%</p>
              <p>Wdrożonych projektów w wyznaczonym terminie</p>
            </div>
            <div class="col-sm-12 col-md-4 text-center">
              <p class="display-3">15+</p>
              <p>Wykonanych stron www oraz platform e-commerce</p>
            </div>
            <div class="col-sm-12 col-md-4 text-center">
              <p class="display-3">3+</p>
              <p>Lata doświadczenia w branży</p>       
            </div>
          </div>
        </div>

        <div id="kontakt" class="container py-5">
          <div class="row row-grid align-items-center justify-content-around">
            <div class="col-md-6 col-sm-12 order-lg-1">
              <img src={Kontakt} alt="" />
            </div>
            <div class="col-md-6 col-sm-12 order-lg-2 my-auto">
                <h2 class="mb-2">Nawiąż<span class="red-dot">_</span>kontakt</h2>
                <p class="lead mb-4">#Porozmawiajmy</p>
                <p class="mb-4">Jeżeli masz pytania odnośnie wyceny lub po prostu chciałbyś nawiązać ze mną kontakt - pisz śmiało na <a href="mailto:kontakt@mgabryel.com">kontakt@mgabryel.com</a> lub telefonicznie pod numerem: <a href="tel:739036056">739 036 056</a>.</p>
                <a class="btn-contact shadow" href="mailto:kontakt@mgabryel.com">
                  <img src={Envelope} width="30px" alt="" />
                  <span>Napisz e-mail</span>
                </a>
                <a class="btn-contact shadow ml-4" href="tel:739036056">
                  <img src={Mobile} width="30px" alt="" />
                  <span>Zadzwoń</span>
                </a>
            </div>
          </div>
        </div>


    </Layout>
)

export default Page
